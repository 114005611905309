import { useState, useEffect } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Box, Grid, Typography, Container } from '@mui/material';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

// ----------------------------------------------------------------------

const firebaseConfig = {
  apiKey: 'AIzaSyCXVqz7MFZiK4242dhqMgAW-BZ_YA72NTU',
  authDomain: 'zamga-c7886.firebaseapp.com',
  databaseURL: 'https://zamga-c7886-default-rtdb.firebaseio.com',
  projectId: 'zamga-c7886',
  storageBucket: 'zamga-c7886.appspot.com',
  messagingSenderId: '882971438642',
  appId: '1:882971438642:web:685327d6bf61dce35d162b',
  measurementId: 'G-YTQQN048LD'
};

firebase.initializeApp(firebaseConfig);

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  }
};

export default function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  // const [u, setU] = useState('');
  // const [ru, setRu] = useState(false);

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
      if (user !== null) {
        fetch(`https://mongo.zaini.co.id/iuser?e=${user.email}`)
          .then(res => res.json())
          .then(
            (r) => {
              if (r.value == null) {
                firebase.auth().signOut();
              }
              console.log(r);
            },
          )
      } else {
        firebase.auth().signOut()
      }
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);
  /*
    useEffect(() => {
      if (u !== '') {
        fetch(`https://mongo.zaini.co.id/iuser?e=${u}`)
          .then(res => res.json())
          .then(
            (r) => {
              if (r.value !== null) { setRu(true) }
              else { setRu(false) };
              console.log(r);
            },
          )
      }
    }, [u]);
  */

  if (!isSignedIn) {
    return (
      <Container>
        <Grid container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}>
          <Box>
            <Typography variant='h6' gutterBottom>Login dengan email @zainiautomotor.com</Typography>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
          </Box>
        </Grid>
      </Container>
    );
  }
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
    </ThemeConfig>
  );
}