import { forwardRef, useState } from 'react';
import firebase from 'firebase/compat/app';
import { useNavigate } from "react-router-dom";
// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Button,
  Card,
  FormControl,
  Select,
  MenuItem,
  Stack,
  TextField,
  Snackbar,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import mergeImages from 'merge-images';
import CanvasDraw from "react-canvas-draw";
// components
import { fNumber } from '../utils/formatNumber';
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const navigate = useNavigate();
  let saveableCanvas;
  const [open, setOpen] = useState(false);
  const [bank, setBank] = useState('');
  const [splate, setSplate] = useState('');
  const [car, setCar] = useState('');
  // const [cust, setCust] = useState('');
  const [odo, setOdo] = useState('');
  const [be, setBe] = useState('10 %');
  const [stnk, setStnk] = useState('');
  const [stnkk, setStnkk] = useState('');
  const [bks, setBks] = useState('');
  const [bksk, setBksk] = useState('');
  const [tset, setTset] = useState('');
  const [tsetk, setTsetk] = useState('');
  const [don, setDon] = useState('');
  const [donk, setDonk] = useState('');
  const [dop, setDop] = useState('');
  const [dopk, setDopk] = useState('');
  const [ban, setBan] = useState('');


  const [mes, setMes] = useState('');
  const [rem, setRem] = useState('');
  const [kop, setKop] = useState('');
  const [ps, setPs] = useState('');
  const [rad, setRad] = useState('');
  const [wip, setWip] = useState('');
  const [im, setIm] = useState('');

  const [loc, setLoc] = useState('');
  const [locd, setLocd] = useState('');
  const [pw, setPw] = useState('');
  const [pwd, setPwd] = useState('');
  const [mir, setMir] = useState('');
  const [mird, setMird] = useState('');
  const [wipp, setWipp] = useState('');
  const [wippd, setWippd] = useState('');

  const [ld, setLd] = useState('');
  const [ldd, setLdd] = useState('');
  const [lb, setLb] = useState('');
  const [lbd, setLbd] = useState('');
  const [lk, setLk] = useState('');
  const [lkd, setLkd] = useState('');
  const [ls, setLs] = useState('');
  const [lsd, setLsd] = useState('');
  const [lr, setLr] = useState('');
  const [lrd, setLrd] = useState('');

  const ssplate = () => {
    fetch(`https://mongo.zaini.co.id/findCustAndCarByPlate?p=${splate}`)
      .then(res => res.json())
      .then(
        (r) => {
          setCar(r);
        },
      )
  }


  const handleSplate = (event) => {
    setSplate((event.target.value).toUpperCase());
  };

  const handleOdo = (event) => {
    setOdo(fNumber(event.target.value));
  };
  const handleBen = (event) => {
    setBe(event.target.value);
  };

  const handleStnk = (event) => {
    setStnk(event.target.value);
  };
  const handleStnkk = (event) => {
    setStnkk(event.target.value);
  };
  const handleBks = (event) => {
    setBks(event.target.value);
  };
  const handleBksk = (event) => {
    setBksk(event.target.value);
  };
  const handleTset = (event) => {
    setTset(event.target.value);
  };
  const handleTsetk = (event) => {
    setTsetk(event.target.value);
  };
  const handleDon = (event) => {
    setDon(event.target.value);
  };
  const handleDonk = (event) => {
    setDonk(event.target.value);
  };
  const handleDop = (event) => {
    setDop(event.target.value);
  };
  const handleDopk = (event) => {
    setDopk(event.target.value);
  };
  const handleBan = (event) => {
    setBan(event.target.value);
  };
  const handleBank = (event) => {
    setBank(event.target.value);
  };
  const handleRem = (event) => {
    setRem(event.target.value);
  };
  const handleMes = (event) => {
    setMes(event.target.value);
  };
  const handleKop = (event) => {
    setKop(event.target.value);
  };
  const handlePs = (event) => {
    setPs(event.target.value);
  };
  const handleRad = (event) => {
    setRad(event.target.value);
  };
  const handleWip = (event) => {
    setWip(event.target.value);
  };
  const handleLoc = (event) => {
    setLoc(event.target.value);
  };
  const handleLocd = (event) => {
    setLocd((event.target.value).toUpperCase());
  };
  const handlePw = (event) => {
    setPw(event.target.value);
  };
  const handlePwd = (event) => {
    setPwd((event.target.value).toUpperCase());
  };
  const handleMir = (event) => {
    setMir(event.target.value);
  };
  const handleMird = (event) => {
    setMird((event.target.value).toUpperCase());
  };
  const handleWipp = (event) => {
    setWipp(event.target.value);
  };
  const handleWippd = (event) => {
    setWippd((event.target.value).toUpperCase());
  };
  const handleLd = (event) => {
    setLd(event.target.value);
  };
  const handleLdd = (event) => {
    setLdd((event.target.value).toUpperCase());
  };
  const handleLb = (event) => {
    setLb(event.target.value);
  };
  const handleLbd = (event) => {
    setLbd((event.target.value).toUpperCase());
  };
  const handleLk = (event) => {
    setLk(event.target.value);
  };
  const handleLkd = (event) => {
    setLkd((event.target.value).toUpperCase());
  };
  const handleLs = (event) => {
    setLs(event.target.value);
  };
  const handleLsd = (event) => {
    setLsd((event.target.value).toUpperCase());
  };
  const handleLr = (event) => {
    setLr(event.target.value);
  };
  const handleLrd = (event) => {
    setLrd((event.target.value).toUpperCase());
  };
  const handleClose = () => {
    setOpen(false);
  };

  const kirim = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        checker: firebase.auth().currentUser.email,
        plate: car.plate,
        vin: car.vin,
        odo,
        ben: be,
        img: im,
        stnk,
        stnkk,
        bks,
        bksk,
        tset,
        tsetk,
        don,
        donk,
        dop,
        dopk,
        ban,
        bank,
        mes,
        rem,
        kop,
        ps,
        rad,
        wip,
        loc,
        locd,
        pw,
        pwd,
        mir,
        mird,
        wipp,
        wippd,
        ld,
        ldd,
        lb,
        lbd,
        lk,
        lkd,
        ls,
        lsd,
        lr,
        lrd,
        user: car.name,
        hp: car.hp,
        car: `${car.make} ${car.model} ${car.trim} ${car.body} ${car.year}`
      })
    };

    if ([stnk,
      bks,
      tset,
      don,
      dop,
      ban,
      mes,
      rem,
      kop,
      ps,
      rad,
      wip,
      loc,
      pw,
      mir,
      wipp,
      ld,
      lb,
      lk,
      ls,
      lr].every(c => c === "")) {
      setOpen(true);
    } else {
      fetch("https://mongo.zaini.co.id/inspectionform", requestOptions)
        .then(navigate("/dashboard/app")).catch(e => console.log(e))
    }
  };

  const kelops = ["Ada", "Tidak Ada"]
  const kelkon = ["Baik", "Kotor", "Rusak"]
  const chem = ["Normal", "Kurang", "Kotor"]
  const elec = ["Normal", "Rusak"]
  const lamp = ["Hidup", "Mati"]
  const ben = ["10 %", "20 %", "30 %", "40 %", "50 %", "60 %", "70 %", "80 %", "90 %", "100 %"]
  /*eslint-disable */
  const cartype = car?.body === "Hatchback 5-Door" ? "/static/city400.png"
    : car?.body === "Sedan" ? "/static/sedan400.png"
      : car?.body === "MPV" ? "/static/mpv400.png"
        : car?.body === "SUV 5-Door" ? "/static/mpv400.png"
          : car?.body === "Minivan" ? "/static/mpv400.png"
            : car?.body === "Pickup Double Cab" ? "/static/truck400.png"
              : car?.body === "Liftback" ? "/static/sedan400.png"
                : "/static/def.png"

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Page title="Pre-service Inspection">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Form tidak boleh ada yang kosong
        </Alert>
      </Snackbar>
      <Container>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Pre-service Inspection</Typography>
        </Box>
        <Typography variant='subtitle1'>Customer : {car.name}</Typography>
        <Typography variant='subtitle1'>Car : {car.make} {car.model} {car.trim} {car.body} {car.year}</Typography>
        <Typography variant='subtitle1'>VIN : {car.vin}</Typography>
        <Box sx={{ pb: 3 }} />
        <TextField
          size='small'
          label="Plate"
          variant="outlined"
          value={splate}
          onChange={handleSplate}
        />
        <Button onClick={ssplate}>Cari</Button>
        <br />
        <Box sx={{ pb: 1 }} />
        <TextField
          size='small'
          label="Odometer"
          variant="outlined"
          value={odo}
          onChange={handleOdo}
        /><br />
        <Box sx={{ pb: 1 }} />
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
          <Typography variant='subtitle2'>Bahan bakar</Typography><FormControl >
            <Select
              id="be"
              value={be}
              onChange={handleBen}
              size='small'
            >
              {ben.map(p => <MenuItem key={`ben${p}`} value={p}>{p}</MenuItem>)}
            </Select>
          </FormControl>
        </Stack>
        <br />
        <Box sx={{ pb: 5 }} />
        {car ?
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='h5'>Kondisi Body</Typography>
                  <Box sx={{ m: 3 }} />
                  <Typography variant='body1'>Coret jika ada goresan, Lingkari jika ada desok</Typography>
                  <Box sx={{ m: 3 }} />
                  <CanvasDraw
                    ref={canvasDraw => (saveableCanvas = canvasDraw)}
                    brushColor="#FF0000"
                    brushRadius={2}
                    // canvasWidth="100%"
                    imgSrc={cartype}
                  />
                  <Box sx={{ m: 3 }} />
                  <Button onClick={() => { saveableCanvas.eraseAll() }}>Clear</Button>
                  <Button onClick={() => { saveableCanvas.undo() }}>Undo</Button>
                  <Button variant="contained" color='info' onClick={() => {
                    mergeImages([cartype, saveableCanvas.getDataURL()], {
                      width: 400,
                      height: 400
                    })
                      .then(b64 => setIm(b64));
                  }}>Save</Button>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='h5'>Kelengkapan</Typography>
                  <Box sx={{ m: 3 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Grid item xs={4} md={4}><Typography>STNK</Typography></Grid>
                    <FormControl >
                      <Select
                        id="stnk"
                        value={stnk}
                        onChange={handleStnk}
                        size='small'
                      >
                        {kelops.map(p => <MenuItem key={`stnk${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {stnk === "Ada" ? <FormControl >
                      <Select
                        id="stnkk"
                        value={stnkk}
                        onChange={handleStnkk}
                        size='small'
                      >
                        {kelkon.map(p => <MenuItem key={`stnk${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl> : null}
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Grid item xs={4} md={4}><Typography>Buku Service</Typography></Grid>
                    <FormControl >
                      <Select
                        id="bks"
                        value={bks}
                        onChange={handleBks}
                        size='small'
                      >
                        {kelops.map(p => <MenuItem key={`bs${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {bks === "Ada" ? <FormControl >
                      <Select
                        labelId="bksk"
                        id="bksk"
                        value={bksk}
                        onChange={handleBksk}
                        size='small'
                      >
                        {kelkon.map(p => <MenuItem key={`bs${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl> : null}
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Grid item xs={4} md={4}><Typography>Tool Set</Typography></Grid>
                    <FormControl >
                      <Select
                        id="tset"
                        value={tset}
                        onChange={handleTset}
                        size='small'
                      >
                        {kelops.map(p => <MenuItem key={`tset${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {tset === "Ada" ? <FormControl >
                      <Select
                        id="tsetk"
                        value={tsetk}
                        onChange={handleTsetk}
                        size='small'
                      >
                        {kelkon.map(p => <MenuItem key={`tsetk${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl> : null}
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Grid item xs={4} md={4}><Typography>Dongkrak</Typography></Grid>
                    <FormControl >
                      <Select
                        id="don"
                        value={don}
                        onChange={handleDon}
                        size='small'
                      >
                        {kelops.map(p => <MenuItem key={`don${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {don === "Ada" ? <FormControl >
                      <Select
                        id="donk"
                        value={donk}
                        onChange={handleDonk}
                        size='small'
                      >
                        {kelkon.map(p => <MenuItem key={`donk${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl> : null}
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Grid item xs={4} md={4}><Typography>Dop Roda</Typography></Grid>
                    <FormControl >
                      <Select
                        id="dop"
                        value={dop}
                        onChange={handleDop}
                        size='small'
                      >
                        {kelops.map(p => <MenuItem key={`dop${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {dop === "Ada" ? <FormControl >
                      <Select
                        id="dopk"
                        value={dopk}
                        onChange={handleDopk}
                        size='small'
                      >
                        {kelkon.map(p => <MenuItem key={`dopk${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl> : null}
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Grid item xs={4} md={4}><Typography>Ban Cadangan</Typography></Grid>
                    <FormControl >
                      <Select
                        id="ban"
                        value={ban}
                        onChange={handleBan}
                        size='small'
                      >
                        {kelops.map(p => <MenuItem key={`ban${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {ban === "Ada" ? <FormControl >
                      <Select
                        id="bank"
                        value={bank}
                        onChange={handleBank}
                        size='small'
                      >
                        {kelkon.map(p => <MenuItem key={`bank${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl> : null}
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='h5'>Chemical</Typography>
                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Grid item xs={6} md={6}><Typography>Oli Mesin</Typography></Grid>
                    <FormControl >
                      <Select
                        id="mes"
                        value={mes}
                        onChange={handleMes}
                        size='small'
                      >
                        {chem.map(p => <MenuItem key={`mes${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Grid item xs={6} md={6}><Typography>Oli Rem</Typography></Grid>
                    <FormControl >
                      <Select
                        id="rem"
                        value={rem}
                        onChange={handleRem}
                        size='small'
                      >
                        {chem.map(p => <MenuItem key={`rem${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Grid item xs={6} md={6}><Typography>Oli Kopling</Typography></Grid>
                    <FormControl >
                      <Select
                        id="kop"
                        value={kop}
                        onChange={handleKop}
                        size='small'
                      >
                        {chem.map(p => <MenuItem key={`kop${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Grid item xs={6} md={6}><Typography>Oli Power Steering</Typography></Grid>
                    <FormControl >
                      <Select
                        id="ps"
                        value={ps}
                        onChange={handlePs}
                        size='small'
                      >
                        {chem.map(p => <MenuItem key={`ps${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Grid item xs={6} md={6}><Typography>Air Radiator</Typography></Grid>
                    <FormControl >
                      <Select
                        id="rad"
                        value={rad}
                        onChange={handleRad}
                        size='small'
                      >
                        {chem.map(p => <MenuItem key={`rad${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                    <Grid item xs={6} md={6}><Typography>Air Wiper</Typography></Grid>
                    <FormControl >
                      <Select
                        id="wip"
                        value={wip}
                        onChange={handleWip}
                        size='small'
                      >
                        {chem.map(p => <MenuItem key={`wip${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='h5'>Electrical</Typography>
                  <Typography variant='caption'>
                    FR : Depan Kanan<br /> FL : Depan Kiri<br /> RR : Belakang Kanan<br /> RL : Belakang Kiri<br />
                  </Typography>


                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                    <Grid item xs={4} md={4}><Typography>Central Lock</Typography></Grid>
                    <FormControl >
                      <Select
                        id="loc"
                        value={loc}
                        onChange={handleLoc}
                        size='small'
                      >
                        {elec.map(p => <MenuItem key={`loc${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {loc === "Rusak" ?
                      <TextField
                        size='small'
                        variant="outlined"
                        value={locd}
                        onChange={handleLocd}
                      />
                      : null}
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                    <Grid item xs={4} md={4}><Typography>Power Window</Typography></Grid>
                    <FormControl >
                      <Select
                        id="pw"
                        value={pw}
                        onChange={handlePw}
                        size='small'
                      >
                        {elec.map(p => <MenuItem key={`pw${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {pw === "Rusak" ?
                      <TextField
                        size='small'
                        variant="outlined"
                        value={pwd}
                        onChange={handlePwd}
                      />
                      : null}
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                    <Grid item xs={4} md={4}><Typography>Mirror</Typography></Grid>
                    <FormControl >
                      <Select
                        id="mir"
                        value={mir}
                        onChange={handleMir}
                        size='small'
                      >
                        {elec.map(p => <MenuItem key={`mir${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {mir === "Rusak" ?
                      <TextField
                        size='small'
                        variant="outlined"
                        value={mird}
                        onChange={handleMird}
                      />
                      : null}
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                    <Grid item xs={4} md={4}><Typography>Wiper</Typography></Grid>
                    <FormControl >
                      <Select
                        id="wipp"
                        value={wipp}
                        onChange={handleWipp}
                        size='small'
                      >
                        {elec.map(p => <MenuItem key={`wipp${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {wipp === "Rusak" ?
                      <TextField
                        size='small'
                        variant="outlined"
                        value={wippd}
                        onChange={handleWippd}
                      />
                      : null}
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='h5'>Lampu</Typography>
                  <Typography variant='caption'>
                    FR : Depan Kanan<br /> FL : Depan Kiri<br /> RR : Belakang Kanan<br /> RL : Belakang Kiri<br />
                  </Typography>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                    <Grid item xs={4} md={4}><Typography>Lampu depan</Typography></Grid>
                    <FormControl >
                      <Select
                        id="ld"
                        value={ld}
                        onChange={handleLd}
                        size='small'
                      >
                        {lamp.map(p => <MenuItem key={`ld${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {ld === "Mati" ?
                      <TextField
                        size='small'
                        variant="outlined"
                        value={ldd}
                        onChange={handleLdd}
                      />
                      : null}
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                    <Grid item xs={4} md={4}><Typography>Lampu belakang</Typography></Grid>
                    <FormControl >
                      <Select
                        id="lb"
                        value={lb}
                        onChange={handleLb}
                        size='small'
                      >
                        {lamp.map(p => <MenuItem key={`lb${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {lb === "Mati" ?
                      <TextField
                        size='small'
                        variant="outlined"
                        value={lbd}
                        onChange={handleLbd}
                      />
                      : null}
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                    <Grid item xs={4} md={4}><Typography>Lampu kota</Typography></Grid>
                    <FormControl >
                      <Select
                        id="lk"
                        value={lk}
                        onChange={handleLk}
                        size='small'
                      >
                        {lamp.map(p => <MenuItem key={`lk${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {lk === "Mati" ?
                      <TextField
                        size='small'
                        variant="outlined"
                        value={lkd}
                        onChange={handleLkd}
                      />
                      : null}
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                    <Grid item xs={4} md={4}><Typography>Lampu sein</Typography></Grid>
                    <FormControl >
                      <Select
                        id="ls"
                        value={ls}
                        onChange={handleLs}
                        size='small'
                      >
                        {lamp.map(p => <MenuItem key={`ls${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {ls === "Mati" ?
                      <TextField
                        size='small'
                        variant="outlined"
                        value={lsd}
                        onChange={handleLsd}
                      />
                      : null}
                  </Stack>

                  <Box sx={{ m: 2 }} />
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                    <Grid item xs={4} md={4}><Typography>Lampu rem</Typography></Grid>
                    <FormControl >
                      <Select
                        id="lr"
                        value={lr}
                        onChange={handleLr}
                        size='small'
                      >
                        {lamp.map(p => <MenuItem key={`lr${p}`} value={p}>{p}</MenuItem>)}
                      </Select>
                    </FormControl>
                    {lr === "Mati" ?
                      <TextField
                        size='small'
                        variant="outlined"
                        value={lrd}
                        onChange={handleLrd}
                      />
                      : null}
                  </Stack>
                </Card>
              </Grid>
            </Grid>
            <Box sx={{ m: 3 }} />
            <Button variant="contained" onClick={kirim}>Kirim</Button>
          </Box> : <Box />}
      </Container>
    </Page >
  );
}
