import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { useNavigate } from "react-router-dom";
import Select from 'react-select'
// material
import {
    Box,
    Grid,
    Container,
    Typography,
    Button,
    Card,
    FormControl,
    MenuItem,
    Stack,
    TextField,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { format, formatDistance } from 'date-fns';
import { id } from 'date-fns/locale';
import Page from '../components/Page';



export default function DashboardApp() {
    const [ran, setRan] = useState(0);
    const [plate, setPlate] = useState("");
    const [openPr, setOpenPr] = useState([]);
    const [closePr, setClosePr] = useState([]);
    const [info, setInfo] = useState("");
    const [urgent, setUrgent] = useState("");
    const [by, setBy] = useState(firebase.auth().currentUser.email.split('@')[0])

    const [plateList, setPlateList] = useState([]);

    useEffect(() => {
        if (plate !== "") {
            fetch(`https://mongo.zaini.co.id/openprfromplate?p=${plate}`)
                .then(res => res.json())
                .then(r => {
                    setOpenPr(r);
                });
            fetch(`https://mongo.zaini.co.id/closeprfromplate?p=${plate}`)
                .then(res => res.json())
                .then(r => {
                    setClosePr(r);
                });
        }
    }, [plate, ran]);

    const rand = () => {
        setRan(Math.random())
    }

    const handlePlateInput = (x) => {
        if (x.length > 3) {
            fetch(`https://mongo.zaini.co.id/searchplatekey?p=${x}`)
                .then(res => res.json())
                .then((r) => setPlateList(r))
        }
        else { console.log('wait') }
    };

    const handleSend = () => {
        fetch('https://mongo.zaini.co.id/addpr', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ plate, info, urgent, by }),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setInfo("");
                rand();
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <Page title="PR / TODO">
            <Container>
                <Box sx={{ pb: 5 }}>
                    <Typography variant="h4">PR / TODO</Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Select
                            options={plateList}
                            onChange={(a) => { setPlate(a.value) }}
                            onInputChange={handlePlateInput}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ pb: 5 }} />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Typography variant="caption" display="block" gutterBottom>
                            Problem
                        </Typography>
                        <TextField
                            fullWidth
                            size="small"
                            id="prname"
                            value={info}
                            onChange={(a) => { setInfo(a.target.value) }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="caption" display="block" gutterBottom>
                            Urgent ?
                        </Typography><Select
                            options={[{ value: "yes", label: "yes" }, { value: "no", label: "no" }]}
                            onChange={(a) => { setUrgent(a.value) }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="caption" display="block" gutterBottom>.
                        </Typography>
                        <Button variant="contained" onClick={() => { handleSend("ok") }}>Kirim</Button>
                    </Grid>
                </Grid>
                <Box sx={{ pb: 5 }} />
                <Card>
                    <Box sx={{ p: 2 }}>
                        <Typography variant="h6"> Open</Typography>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Urgent?</TableCell>
                                        <TableCell >Open Date</TableCell>
                                        <TableCell >Info</TableCell>
                                        <TableCell >By</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {openPr.map((x) =>
                                        <TableRow key={x.id}>
                                            <TableCell><span role="img" aria-label='ok'>{x.urgent === "yes" ? "🔴 " : "🟡 "} </span>{x.urgent}</TableCell>
                                            <TableCell>{x.date == null ? "" : format(new Date(x.date), 'dd MMMM yyy, hh:mm aaa')}</TableCell>
                                            <TableCell>{x.info}</TableCell>
                                            <TableCell>{x.by}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{ pb: 5 }} />
                        <Typography variant="h6"> Close</Typography>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Urgent?</TableCell>
                                        <TableCell >Open Date</TableCell>
                                        <TableCell >Info</TableCell>
                                        <TableCell >Close Date</TableCell>
                                        <TableCell >By</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {closePr.map((x) =>
                                        <TableRow key={x.id}>
                                            <TableCell><span role="img" aria-label='ok'>🟢 </span>{x.urgent}</TableCell>
                                            <TableCell>{x.date == null ? "" : format(new Date(x.date), 'dd MMMM yyy, hh:mm aaa')}</TableCell>
                                            <TableCell>{x.info}</TableCell>
                                            <TableCell>{x.cdate == null ? "" : format(new Date(x.cdate), 'dd MMMM yyy, hh:mm aaa')}</TableCell>
                                            <TableCell>{x.by}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Card>
            </Container>
        </Page>
    )
}