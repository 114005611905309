import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import warn from '@iconify/icons-eva/alert-triangle-outline';
import briefcasefill from '@iconify/icons-eva/briefcase-fill';
import clipboardo from '@iconify/icons-eva/clipboard-outline';
import clipboardf from '@iconify/icons-eva/clipboard-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'work order',
    path: '/dashboard/user',
    icon: getIcon(briefcasefill)
  },
  {
    title: 'pre-service inspection',
    path: '/dashboard/inspection',
    icon: getIcon(clipboardo)
  },
  {
    title: 'PR / todo',
    path: '/dashboard/pr',
    icon: getIcon(warn)
  },
  {
    title: 'General Check',
    path: '/dashboard/gc',
    icon: getIcon(clipboardf)
  },
  /*
 {
   title: 'product',
   path: '/dashboard/products',
   icon: getIcon(shoppingBagFill)
 },
 {
   title: 'blog',
   path: '/dashboard/blog',
   icon: getIcon(fileTextFill)
 },

 {
   title: 'login',
   path: '/login',
   icon: getIcon(lockFill)
 },
 {
   title: 'register',
   path: '/register',
   icon: getIcon(personAddFill)
 },
 {
   title: 'Not found',
   path: '/404',
   icon: getIcon(alertTriangleFill)
 }
 */
];

export default sidebarConfig;
